import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { createApp } from "vue";

import { Dashboard } from "./views/Dashboard";
import { fallbackRoute, router } from "./router/Router";
import VueSignaturePad from "vue-signature-pad";

import "./dashboard.css";

export let loggedIn = false;
export let loadedApp = false;
onAuthStateChanged(auth, async (user) => {
  loggedIn = !!user;

  if (loggedIn) {
    if (router.currentRoute.value.query.redirect) {
      const redirectUrl = decodeURIComponent(
        router.currentRoute.value.query.redirect.toString()
      );
      router.push(redirectUrl);
    } else if (router.currentRoute.value.name === "login") {
      router.push({ name: fallbackRoute() });
    }
  } else if (router.currentRoute.value.name !== "login") {
    router.push({
      name: "login",
      query: {
        redirect: encodeURIComponent(router.currentRoute.value.fullPath),
      },
    });
  }

  if (!loadedApp) {
    createApp(Dashboard).use(router).use(VueSignaturePad).mount("#app");
    loadedApp = true;
  }
});
