import { defineComponent, h, Transition } from "vue";
import { RouterView } from "vue-router";

export const Dashboard = defineComponent({
  render() {
    return h(
      <div class="absolute top-0 left-0 p-8 h-full w-full flex justify-center items-center">
        <Transition name="fade">
          <RouterView class="absolute top-0 left-0 w-full h-full" />
        </Transition>
      </div>
    );
  },
});
