import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { loggedIn } from "@/main";
import { treatmentRoutes } from "./treatments";
import { surveyRoutes } from "./surveys";
import { templateRoutes } from "./templates";
import { formRoutes } from "./forms";

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
    meta: {
      category: "login",
      level: 0,
    },
  },
  {
    path: "/enquete/:id",
    name: "survey-form",
    component: () => import("@/views/SurveyForm/SurveyForm"),
    meta: {
      category: "survey",
      level: 0,
    },
  },
  {
    path: "/formulier/:config",
    name: "permission-form",
    component: () => import("@/views/PermissionForm/PermissionForm"),
    meta: {
      category: "permission",
      level: 0,
    },
  },
  {
    path: "/f/:id",
    name: "permission-form-treatment",
    component: () => import("@/views/PermissionForm/PermissionForm"),
    meta: {
      category: "permission",
      level: 0,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/ResetPassword"),
    meta: {
      category: "reset-password",
      level: 0,
    },
  },
  {
    path: "/triage",
    name: "triage",
    component: () => import("@/views/Triage/Triage"),
    meta: {
      category: "triage",
      level: 0,
    },
  },
  {
    path: "/manage",
    name: "admin-dashboard",
    component: () => import("@/views/ManageApp"),
    children: [
      ...surveyRoutes,
      ...treatmentRoutes,
      ...templateRoutes,
      ...formRoutes,
    ],
    meta: {
      category: "admin",
      level: 0,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export function fallbackRoute() {
  if (loggedIn) {
    return "admin-dashboard";
  } else {
    return "login";
  }
}

router.beforeEach((to, _, next) => {
  const fallback = fallbackRoute();
  if (to.path === "/" && loggedIn) {
    next({ name: fallback });
  } else if (loggedIn) {
    next();
  } else if (
    [
      "reset-password",
      "survey-form",
      "permission-form",
      "permission-form-treatment",
      "triage",
    ].includes(to.name?.toString() ?? "")
  ) {
    next();
  } else if (fallback === "login" && to.name !== "login") {
    next({
      name: "login",
      query: { redirect: encodeURIComponent(to.fullPath) },
    });
  } else if (fallback !== "login" && to.name !== fallback) {
    next({ name: fallback });
  } else if (to.name === "login") {
    next();
  } else {
    next(false);
  }
});
