import { RouteRecordRaw } from "vue-router";

export const formRoutes = [
  {
    path: "form-url-creator",
    name: "form-url-creator",
    component: () => import("@/views/FormUrlCreator/FormUrlCreator"),
    meta: {
      category: "form-url-creator",
      level: 1,
    },
  },
  {
    path: "pdf-list",
    name: "pdf-list",
    component: () => import("@/views/PDFList/PDFList"),
    meta: {
      category: "pdf-list",
      level: 1,
    },
  },
] as RouteRecordRaw[];
