export enum QuestionType {
  TextBlock = "text-block",
  MultipleChoice = "multiple-choice",
  Rating = "rating",
  OpenEnded = "open-ended",
}

export interface GeneralQuestionOptions {
  required: boolean;
  text: string;
  elaboration: {
    enabled: boolean;
    text: string;
    placeholder: string;
  };
}

export interface MultipleChoiceOptions extends GeneralQuestionOptions {
  question: string;
  required: boolean;
  choices: { id: string; label: string }[];
  multipleAnswers: boolean;
  minAnswers: number;
  maxAnswers: number;
}

export interface RatingOptions extends GeneralQuestionOptions {
  question: string;
  amountOfStars: number;
}

export interface OpenEndedOptions extends GeneralQuestionOptions {
  questions: { id: string; question: string }[];
}

export interface Question {
  id: string;
  type: QuestionType;
  options:
    | GeneralQuestionOptions
    | MultipleChoiceOptions
    | RatingOptions
    | OpenEndedOptions;
}

export interface SurveySection {
  id: string;
  title: string;
  questions: Question[];
}

export class Survey {
  public name: string;
  public introduction: string;
  public thanks: string;
  public slug: string;
  public published: boolean; 
  public created: Date;
  public triage: {
    enabled: boolean;
    customName: boolean;
    name: string;
  };
  public sections: SurveySection[];

  validate(): { valid: boolean; errors: string[] } {
    const errors: string[] = [];

    if (this.name.length === 0) {
      errors.push("Naam moet ingevuld zijn");
    }

    if (this.slug.length === 0) {
      errors.push("URL moet ingevuld zijn");
    }

    return { valid: errors.length === 0, errors };
  }

  constructor(data?: any) {
    this.name = data?.name ?? "Nieuwe enquête";
    this.introduction = data?.introduction ?? "";
    this.thanks = data?.thanks ?? "";
    this.slug = data?.slug ?? "niewe-enquete";
    this.published = data?.published ?? false;
    this.created = data?.created ?? new Date();
    this.triage = {
      enabled: data?.triage?.enabled ?? false,
      customName: data?.triage?.customName ?? false,
      name: data?.triage?.name ?? "Nieuwe enquête",
    };
    this.sections = data?.sections ?? [];
  }
}
