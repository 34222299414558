import { Template } from "@data/forms/Template";
import { DocumentData } from "firebase/firestore";

export class TemplateClientConverter {
  public static toFirestore(template: Template): DocumentData {
    return {
      ...template,
    };
  }
  public static fromFirestore(data: DocumentData): Template {
    if (typeof data?.data === "function") {
      const loaded = data.data();
      return new Template({
        ...loaded,
      });
    } else {
      return new Template({
        ...data,
      });
    }
  }
}
