// Firebase
import firebaseConfig from "../../../firebase.json";
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Data Converters
import { SurveyClientConverter } from "@converter/SurveyClientConverter";
import { TemplateClientConverter } from "@converter/TemplateClientConverter";
import { FormSubmissionClientConverter } from "@converter/FormSubmissionClientConverter";

export const app = initializeApp({
  apiKey: "AIzaSyA9rkzh_IlZRvAHH-d1TUbfsTt-Qd_Qk3U",
  authDomain: "mooi-forms.firebaseapp.com",
  projectId: "mooi-forms",
  storageBucket: "mooi-forms.appspot.com",
  messagingSenderId: "564662266997",
  appId: "1:564662266997:web:59d876fd7bd86d7dc51c3c",
  measurementId: "G-NXBB00D70B",
});

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, "europe-west1");

if (process.env.VUE_APP_APPCHECK_DEBUG_TOKEN) {
  console.log("Using debug token for AppCheck");
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.VUE_APP_APPCHECK_DEBUG_TOKEN;
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdVQo4dAAAAAA75dp9rzLVqAnNM82gufA2Aum4O"),
  isTokenAutoRefreshEnabled: true,
});

export const formsUrl = (() => {
  if (process.env.NODE_ENV === "development") {
    return `http://0.0.0.0:3000`;
  } else if (window.location.href.includes("web.app")) {
    return "https://mooi-forms.web.app";
  } else {
    return "https://formulieren.mooikliniek.nl";
  }
})();

export const collections = {
  surveys() {
    return collection(firestore, "surveys").withConverter(
      SurveyClientConverter
    );
  },
  templates() {
    return collection(firestore, "templates").withConverter(
      TemplateClientConverter
    );
  },
  submissions() {
    return collection(firestore, "submissions").withConverter(
      FormSubmissionClientConverter
    );
  },
};

if (process.env.NODE_ENV === "development") {
  console.log("Connecting Firebase Emulators");

  const authEmulatorHost =
    process.env.VUE_APP_AUTH_URL ??
    `http://0.0.0.0:${firebaseConfig.emulators.auth.port}`;
  console.log("Connecting Auth Emulator to", authEmulatorHost);
  connectAuthEmulator(auth, authEmulatorHost, { disableWarnings: true });

  const firestoreEmulatorHost = (process.env.VUE_APP_FIRESTORE_URL ?? `0.0.0.0`)
    .replace("http://", "")
    .replace("https://", "");
  const firestoreEmulatorPort = process.env.VUE_APP_FIRESTORE_URL
    ? 80
    : firebaseConfig.emulators.firestore.port;
  console.log(
    `Connecting Firestore Emulator to: http://${firestoreEmulatorHost}:${firestoreEmulatorPort}`
  );
  connectFirestoreEmulator(
    firestore,
    firestoreEmulatorHost,
    firestoreEmulatorPort
  );

  const functionsEmulatorHost = (process.env.VUE_APP_FUNCTIONS_URL ?? `0.0.0.0`)
    .replace("http://", "")
    .replace("https://", "");
  const functionsEmulatorPort = process.env.VUE_APP_FUNCTIONS_URL
    ? 80
    : firebaseConfig.emulators.functions.port;
  console.log(
    `Connecting Functions Emulator to: http://${functionsEmulatorHost}:${functionsEmulatorPort}`
  );
  connectFunctionsEmulator(
    functions,
    functionsEmulatorHost,
    functionsEmulatorPort
  );
}
