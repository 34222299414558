import { RouteRecordRaw } from "vue-router";

export const templateRoutes = [
  {
    path: "templates",
    name: "templates",
    component: () => import("@/views/Templates/TemplateList"),
    meta: {
      category: "templates",
      level: 1,
    },
    children: [
      {
        path: ":id",
        name: "template",
        component: () => import("@/views/Templates/TemplateView"),
        props(route) {
          return {
            id: route.params.id ?? "",
          };
        },
        meta: {
          category: "templates",
          level: 2,
        },
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
