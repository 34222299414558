import { RouteRecordRaw } from "vue-router";

export const treatmentRoutes = [
  {
    path: "treatments",
    name: "treatments",
    component: () => import("@/views/Treatments"),
    meta: {
      category: "treatments",
      level: 1,
    },
  },
] as RouteRecordRaw[];
