import { DocumentData, Timestamp } from "firebase/firestore";
import { FormSubmission } from "../../../data/forms/FormSubmission";

export class FormSubmissionClientConverter {
  static toFirestore(submission: FormSubmission): DocumentData {
    return {
      ...submission,
      created: Timestamp.fromDate(submission.created),
      lastDownloaded: submission.lastDownloaded
        ? Timestamp.fromDate(submission.lastDownloaded)
        : null,
      values: {
        ...submission.values,
        customFields: (() => {
          const customFields: {
            [key: string]: string | Timestamp | number | null;
          } = {};
          Object.keys(submission.values.customFields ?? {}).forEach((key) => {
            const value = submission.values.customFields[key];
            if (value instanceof Date) {
              customFields[key] = Timestamp.fromDate(value);
            } else {
              customFields[key] = value;
            }
          });
          return customFields;
        })(),
        birthdate: Timestamp.fromDate(
          new Date(submission.values.birthdate ?? "")
        ),
        date: Timestamp.fromDate(submission.values.date),
      },
    };
  }
  static fromFirestore(data: DocumentData): FormSubmission {
    if (typeof data?.data === "function") {
      const loaded = data.data();
      return new FormSubmission({
        ...loaded,
        created: new Timestamp(
          loaded.created.seconds,
          loaded.created.nanoseconds
        ).toDate(),
        lastDownloaded: loaded.lastDownloaded
          ? new Timestamp(
              loaded.lastDownloaded.seconds,
              loaded.lastDownloaded.nanoseconds
            ).toDate()
          : null,
        values: {
          ...loaded.values,
          customFields: (() => {
            const customFields: { [key: string]: Date | string | number } = {};
            Object.keys(loaded.values.customFields ?? {}).forEach((key) => {
              const value = loaded.values.customFields[key];
              if (value.seconds && value.nanoseconds) {
                customFields[key] = new Timestamp(
                  value.seconds,
                  value.nanoseconds
                ).toDate();
              } else {
                customFields[key] = value;
              }
            });
            return customFields;
          })(),
          birthdate: new Timestamp(
            loaded.values.birthdate.seconds,
            loaded.values.birthdate.nanoseconds
          ).toDate(),
          date: new Timestamp(
            loaded.values.date.seconds,
            loaded.values.date.nanoseconds
          ).toDate(),
        },
      });
    } else {
      return new FormSubmission({
        ...data,
        created: new Timestamp(
          data.created?.seconds,
          data.created?.nanoseconds
        ).toDate(),
        lastDownloaded: data.lastDownloaded
          ? new Timestamp(
              data.lastDownloaded.seconds,
              data.lastDownloaded.nanoseconds
            ).toDate()
          : null,
        values: {
          ...data.values,
          customFields: (() => {
            const customFields: { [key: string]: Date | string | number } = {};
            Object.keys(data.values.customFields ?? {}).forEach((key) => {
              const value = data.values.customFields[key];
              if (value.seconds && value.nanoseconds) {
                customFields[key] = new Timestamp(
                  value.seconds,
                  value.nanoseconds
                ).toDate();
              } else {
                customFields[key] = value;
              }
            });
            return customFields;
          })(),
          birthdate: new Timestamp(
            data.values.birthdate?.seconds,
            data.values.birthdate?.nanoseconds
          ).toDate(),
          date: new Timestamp(
            data.values.date?.seconds,
            data.values.date?.nanoseconds
          ).toDate(),
        },
      });
    }
  }
}
