import { RouteRecordRaw } from "vue-router";

export const surveyRoutes = [
  {
    path: "surveys",
    name: "surveys",
    component: () => import("@/views/Surveys/SurveyList"),
    meta: {
      category: "surveys",
      level: 1,
    },
    children: [
      {
        path: ":id",
        name: "survey",
        component: () => import("@/views/Surveys/SurveyView"),
        props(route) {
          return {
            id: route.params.id ?? "",
          };
        },
        meta: {
          category: "surveys",
          level: 2,
        },
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
