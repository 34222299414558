export interface Permission {
  id: string;
  name: string;
  required: boolean;
}

export interface CustomField {
  id: string;
  name: string;
  type: "text" | "multiline" | "date" | "number";
  required: boolean;
}

export class Template {
  public name: string;
  public hasTreatment: boolean;
  public content: string;
  public permissions: Permission[];
  public customFields: CustomField[];
  public thanks: string;
  public parentName: boolean;
  public published: boolean;
  public signatureRequired: boolean;
  public short_id: string;

  constructor(data?: any) {
    this.name = data?.name ?? "";
    this.hasTreatment = data?.hasTreatment ?? false;
    this.content = data?.content ?? "";
    this.permissions = data?.permissions ?? [];
    this.customFields = data?.customFields ?? [];
    this.thanks = data?.thanks ?? "";
    this.parentName = data?.parentName ?? false;
    this.published = data?.published ?? false;
    this.signatureRequired = data?.signatureRequired ?? false;
    this.short_id = data?.short_id ?? "";
  }
}
