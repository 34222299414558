import { DocumentData, Timestamp } from "firebase/firestore";
import { Survey } from "@data/surveys/Survey";

export class SurveyClientConverter {
  static toFirestore(form: Survey): DocumentData {
    return {
      ...form,
      slug: form.slug.trim(),
      created: Timestamp.fromDate(form.created),
      sections: form.sections.map((section) => ({
        ...section,
        questions: section.questions.map((question) => ({
          ...question,
          options: (() => {
            const options: { [key: string]: unknown } = { ...question.options };
            for (const key of Object.keys(question.options)) {
              if (key === "text" && key in question.options) {
                options[key] = question.options.text;
              } else if (key === "elaboration" && key in question.options) {
                options[key] = {
                  ...question.options.elaboration,
                };
              } else if (key === "choices" && key in question.options) {
                options[key] = question.options.choices.map((choice) => ({
                  ...choice,
                }));
              }
            }
            return options;
          })(),
        })),
      })),
    };
  }

  static fromFirestore(data: DocumentData): Survey {
    if (typeof data?.data === "function") {
      const loaded = data.data();
      return new Survey({
        ...loaded,
        slug: loaded.slug.trim(),
        created: loaded.created.toDate(),
        triage: { ...loaded.triage },
        sections: loaded.sections.map((section: DocumentData) => ({
          ...section,

          questions: section.questions.map((question: DocumentData) => ({
            ...question,

            options: (() => {
              const options: { [key: string]: unknown } = {
                ...question.options,
              };
              for (const key of Object.keys(question.options)) {
                if (key === "text" && key in question.options) {
                  options[key] = question.options.text;
                } else if (key === "elaboration" && key in question.options) {
                  options[key] = { ...question.options.elaboration };
                } else if (key === "choices" && key in question.options) {
                  options[key] = question.options.choices.map(
                    (choice: DocumentData) => ({ ...choice })
                  );
                }
              }
              return options;
            })(),
          })),
        })),
      });
    } else {
      return new Survey({
        ...data,
        slug: data.slug.trim(),
        created: data.created.toDate(),
        triage: {
          ...data.triage,
        },
        sections: data.sections.map((section: DocumentData) => ({
          ...section,
          questions: section.questions.map((question: DocumentData) => ({
            ...question,
            options: (() => {
              const options: { [key: string]: unknown } = {
                ...question.options,
              };
              for (const key of Object.keys(question.options)) {
                if (key === "text" && key in question.options) {
                  options[key] = question.options.text;
                } else if (key === "elaboration" && key in question.options) {
                  options[key] = {
                    ...question.options.elaboration,
                  };
                } else if (key === "choices" && key in question.options) {
                  options[key] = question.options.choices.map(
                    (choice: DocumentData) => ({
                      ...choice,
                    })
                  );
                }
              }
              return options;
            })(),
          })),
        })),
      });
    }
  }
}
