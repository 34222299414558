import { Template } from "./Template";

export class FormSubmission {
  public created: Date;
  public template: string;
  public lastDownloaded: Date | null;
  public values: {
    name: {
      first: string;
      last: string;
    };
    guardianName: {
      first: string;
      last: string;
    };
    place: string;
    signature: string;
    permissions: { [key: string]: boolean };
    customFields: { [key: string]: string | Date | number | null };
    birthdate: Date | null;
    date: Date;
    treatment: string;
  };

  validate(template: Template): {
    valid: boolean;
    errors: string[];
    verifications: {
      name: string;
      valid: boolean;
    }[];
  } {
    const errors = [] as string[];
    const verifications = [
      {
        name: "Voornaam",
        valid: this.values.name.first.length > 0,
      },
      {
        name: "Achternaam",
        valid: this.values.name.last.length > 0,
      },
      {
        name: "Geboortedatum",
        valid:
          this.values.birthdate !== null && this.values.birthdate !== undefined,
      },
    ] as {
      name: string;
      valid: boolean;
    }[];

    if (template.signatureRequired) {
      verifications.push(
        ...[
          {
            name: "Plaats",
            valid: this.values.place.length > 0,
          },
          {
            name: "Handtekening",
            valid: this.values.signature.length > 0,
          },
          {
            name: "Onderteken datum",
            valid: this.values.date !== null && this.values.date !== undefined,
          },
        ]
      );
    }

    if (template.parentName) {
      verifications.push(
        {
          name: "Voornaam ouder/wettelijke vertegenwoordiger",
          valid: this.values.guardianName.first.length > 0,
        },
        {
          name: "Achternaam ouder/wettelijke vertegenwoordiger",
          valid: this.values.guardianName.last.length > 0,
        }
      );
    }

    for (const permission of template.permissions) {
      if (permission.required) {
        verifications.push({
          name: permission.name,
          valid: this.values.permissions[permission.id],
        });
      }
    }

    for (const field of template.customFields) {
      if (field.required && (field.type == "date" || field.type == "number")) {
        verifications.push({
          name: field.name,
          valid: this.values.customFields[field.id] !== null,
        });
      } else if (
        field.required &&
        (field.type == "text" || field.type == "multiline")
      ) {
        verifications.push({
          name: field.name,
          valid:
            (this.values.customFields[field.id] ?? "").toString().trim()
              .length > 1,
        });
      }
    }

    for (const verification of verifications) {
      if (!verification.valid) {
        errors.push(`${verification.name} moet worden ingevuld`);
      }
    }

    return { valid: errors.length === 0, errors, verifications };
  }

  constructor(data?: any) {
    this.created = data?.created ?? new Date();
    this.template = data?.template ?? "";
    this.lastDownloaded = data?.lastDownloaded ?? null;
    this.values = {
      name: {
        first: data?.values?.name?.first ?? "",
        last: data?.values?.name?.last ?? "",
      },
      guardianName: {
        first: data?.values?.guardianName?.first ?? "",
        last: data?.values?.guardianName?.last ?? "",
      },
      place: data?.values?.place ?? "",
      signature: data?.values?.signature ?? "",
      permissions: data?.values?.permissions ?? {},
      customFields: data?.values?.customFields ?? {},
      birthdate: data?.values?.birthdate ?? null,
      date: data?.values?.date ?? new Date(),
      treatment: data?.values?.treatment ?? "",
    };
  }
}
